.SearchInputHeaderPublic {
  outline: none !important;
  box-shadow: none !important;
  border-top: 2px solid var(--trinary-color) !important;
  border-left: 2px solid var(--trinary-color) !important;
  border-bottom: 2px solid var(--trinary-color) !important;
  resize: none;
  box-shadow: none !important;
}

.SearchInputHeaderPublic:focus {
  outline: none !important;
  box-shadow: none !important;
  border-top: 2px solid var(--trinary-color) !important;
  border-left: 2px solid var(--trinary-color) !important;
  border-bottom: 2px solid var(--trinary-color) !important;
  resize: none;
  box-shadow: none !important;
}

.SearchInputHeaderPublic {
  font-size: 11px !important;
  font-weight: 700;
  color: var(--secondary-color) !important;
}
