:root {
    --primary-color: #33cc33;
    --secondary-color: #cccccc;
    --trinary-color: #b3b3b3;
    --quartery-color: #ff0000;
  }

  .btn-primary{
    background-color:#33cc33 !important;
    color:white !important;
    outline: none !important;
    font-weight: bold;
    border:none;
  }
  .btn-primary:hover{
    background-color:#3cdf3c !important;
    color:white !important;
    outline: none !important;
    font-weight: bold;
    border:none;
  }

  .btn-secondary{
    background-color:#cccccc !important;
    color:black !important;
    outline: none !important;
    font-weight: bold;
    border:none;
  }
  .btn-secondary:hover{
    background-color:#9b9b9b !important;
    color:black !important;
    outline: none !important;
    font-weight: bold;
    border:none;
  }


  /* Override Bootstrap's primary color */
  .bg-primary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
  }
  
  .text-primary {
    color: var(--primary-color) !important;
  }
  
  /* Override Bootstrap's secondary color */
  .bg-secondary {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
  }
  
  .text-secondary {
    color: var(--secondary-color) !important;
  }


.bg-trinary{
    background-color: var(--trinary-color) !important;
    border-color: var(--trinary-color) !important;
}
.text-trinary{
    color: var(--trinary-color) !important;
}

.bg-quartery{
  background-color: var(--quartery-color) !important;
  border-color: var(--quartery-color) !important;
}
.text-quartery{
  color: var(--quartery-color) !important;
}


.form-check-input:checked {
    background-color: var(--secondary-color) !important;
    border-color: var(--secondary-color) !important;
}


.form-control {
  color: black !important;
  outline: none !important;
  box-shadow: none !important;
  border-top: 2px solid var(--trinary-color) ;
  border-left: 2px solid var(--trinary-color) ;
  border-bottom: 2px solid var(--trinary-color) ;
  resize: none;
  box-shadow: none !important;
}

.form-control:focus {
  color: black !important;
  outline: none !important;
  box-shadow: none !important;
  border-top: 2px solid var(--trinary-color) ;
  border-left: 2px solid var(--trinary-color) ;
  border-bottom: 2px solid var(--trinary-color) ;
  resize: none;
  box-shadow: none !important;
}

.form-control::placeholder {
  color: var(--secondary-color) !important;
}

.input-group-text-right{
  color: var(--trinary-color) !important;
  border-top: 2px solid var(--trinary-color) ;
  border-bottom: 2px solid var(--trinary-color) ;
  border-right: 2px solid var(--trinary-color) ;
}

/* Set the width of the input group to 200px on medium screens */
/* PHONE VERSION */
@media only screen and (min-width: 320px) {
  .mainWrapper{
    width: 100%;
  }
  .headerWrapper{
    max-width: 100%;
  }
  .FooterNavMobileContainer{
    max-width: 100%;
  }
}
/* TABLET VERSION */
@media only screen and (min-width: 768px) {
  .mainWrapper{
    max-width: 430px;
  }
  .headerWrapper{
    max-width: 430px;
  }
  .FooterNavMobileContainer{
    max-width: 430px;
  }
}
/* DESKTOP VERSION */
@media only screen and (min-width: 980px) {
  .mainWrapper{
    max-width: 430px;
  }
  .headerWrapper{
    max-width: 430px;
  }
  .FooterNavMobileContainer{
    max-width: 430px;
  }
}



.ContentContainer{
  padding-top:50px;
  padding-bottom:50px;
  overflow-y: auto;
}

@keyframes fadeInFromBottom {
    from {
      opacity: 0;
      transform: translateY(100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }


  /* Select the vertical scrollbar */
::-webkit-scrollbar {
  width: 5px; /* Adjust the width as desired */
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffff; /* Change track color if needed */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: --secondary-color; /* Change thumb color if needed */
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b2bec3; /* Change thumb color on hover if needed */
}